import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import SideBar from '../components/sidebar'

import IndexSEOImage from '../images/wood-roof-construction.jpg'

const About = () => (
    <StaticQuery
    query={graphql`
        query AboutMeta {
            site {
                siteMetadata {
                    title
                    description
                    google {
                        googleMapAPIKey
                        googleReCaptchaKey
                    }
                }
            }
        }
    `}
    render={data => (
        <>
        <Layout page='about'>
            <SEO title='About Us' description={`Founded in 2010, Ochoa Enterprises’ mission is to provide quality workmanship and customer service while maintaining the highest level of professionalism.`} image={IndexSEOImage} keywords={[``]} />
            <Banner title='About Us' image={IndexSEOImage} subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
            <section>
                <Container>
                    <Row>
                        <Col xs='12' sm='12' md='8' lg='8'>
                            <div className='spacer20'></div>
                            <h1>Our Mission</h1>

                            <p>Founded in 2010, Ochoa Enterprises’ mission is to provide quality workmanship and customer service while maintaining the highest level of professionalism. Our mission is to provide value added construction services to our clients by creating successful partnership with them through the project’s construction phases. To provide quality construction services at fair and market competitive prices. To ensure the longevity of our firm through repeat and referral business achieved by customer satisfaction.</p>

                            <div className='spacer20'></div>

                            <h1>Who We Are</h1>
                            <p>Ochoa Enterprises is a forward-thinking innovator and entrepreneur who founded the business in 2010. Ochoa Enterprises is a General Contractor/Construction Management firm based out of Northern New Jersey.  Over the years, our company has enjoyed continued growth thanks to our well-earned reputation as well as our quality and commitment to exceeding our client’s expectations. We provide a full range of construction services to our clients which include general contracting services. These services include the design phase, construction phase through the maintenance phase.</p>
                            <p>We also provide a range of Construction Management services to meet our client’s needs and make ourselves responsible for all services necessary for a complete and successful project. The firm is known for its ability to take a project from design concept to project completion when contracted as a design/build team.</p>

                            <div className='spacer50'></div>
                        </Col>
                        <SideBar />
                    </Row>
                </Container>
            </section>
        </Layout>
        </>
        )}
    />
)

export default About
