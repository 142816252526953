import React from 'react'
import { Link } from 'gatsby'
import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SideBar = ({ children }) => (
    <Col className='sidebarBox' xs='12' sm='12' md='4' lg='4'>
        {children}
        <h3>Our Services</h3>
        <p>Ochoa Enterprises offers planning, coordination, budgeting and overseeing every aspect of your residential or commercial build, construction management of a project, and specialty work upon request.</p>
        <ul>
            <li><FontAwesomeIcon className='fa-li' icon='chevron-right' /> <Link to='#'>View All</Link></li>
        </ul>
    </Col>
)

export default SideBar
